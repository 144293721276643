{
  "name": "front",
  "version": "1.11.8-rc1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host 0.0.0.0",
    "dev": "ng serve --proxy-config proxy.conf.json",
    "recette": "ng serve -c=recette",
    "integration": "ng serve -c=integration",
    "timestamp": "node tools/timestamp.js",
    "prebuild": "npm run timestamp",
    "build": "ng build",
    "start-build": "ng build --configuration production",
    "type-check": "tsc --noEmit -p tsconfig.json --composite false",
    "lint": "eslint . --ext .vue,.js,.jsx,.cjs,.mjs,.ts,.tsx,.cts,.mts --fix --ignore-path .gitignore",
    "format": "prettier --write src/"
  },
  "private": true,
  "dependencies": {
    "@ag-grid-community/angular": "~30.2.1",
    "@ag-grid-community/client-side-row-model": "~30.2.1",
    "@ag-grid-community/core": "~30.2.1",
    "@ag-grid-community/csv-export": "~30.2.1",
    "@ag-grid-community/styles": "~30.2.1",
    "@angular/animations": "^17.0.6",
    "@angular/cdk": "^17.0.3",
    "@angular/common": "^17.0.6",
    "@angular/compiler": "^17.0.6",
    "@angular/core": "^17.0.6",
    "@angular/forms": "^17.0.6",
    "@angular/material": "^17.0.3",
    "@angular/platform-browser": "^17.0.6",
    "@angular/platform-browser-dynamic": "^17.0.6",
    "@angular/router": "^17.0.6",
    "@angular/service-worker": "^17.0.6",
    "@azure/msal-angular": "^3.0.15",
    "@azure/msal-browser": "^3.11.1",
    "@googlemaps/js-api-loader": "^1.16.2",
    "@microsoft/applicationinsights-web": "^2.8.8",
    "bootstrap": "~4.6.2",
    "chart.js": "~3.9.1",
    "html2canvas": "~1.4.1",
    "ng2-charts": "^4.1.1",
    "ngx-extended-pdf-viewer": "^18.1.10",
    "ngx-mat-select-search": "~7.0.5",
    "ngx-toastr": "^15.2.2",
    "primeicons": "^6.0.1",
    "primeng": "~17.0.0",
    "rxjs": "~7.8.1",
    "tslib": "~2.4.0",
    "typescript": "~5.2.2",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.6",
    "@angular-devkit/schematics": "^17.0.6",
    "@angular-eslint/builder": "17.1.1",
    "@angular-eslint/eslint-plugin": "17.1.1",
    "@angular-eslint/eslint-plugin-template": "17.1.1",
    "@angular-eslint/schematics": "17.1.1",
    "@angular-eslint/template-parser": "17.1.1",
    "@angular/cli": "^17.0.6",
    "@angular/compiler-cli": "^17.0.6",
    "@angular/language-service": "^17.0.6",
    "@types/google.maps": "^3.54.10",
    "@types/node": "~20.3.1",
    "@typescript-eslint/eslint-plugin": "^6.10.0",
    "@typescript-eslint/parser": "^6.10.0",
    "eslint": "^8.53.0",
    "prettier": "^2.8.8",
    "ts-node": "~10.9.1"
  }
}
